// Variables
@import 'variables';

.primary-border-left{
    border-left: 5px solid $primary-color;
}

.primary-background-color{
    background-color: $primary-color;
}

.primary-blue-background{
    background-color: $blue;
}

.primary-text-color{
    color: $primary-color !important;
}

.primary-text-light-color{
    color: $primary-blue-color !important;
}

.page-title{
    background-color: $primary-color;
    color: #FFF;
    padding: 5px 15px;
    display: inline-block;
    box-shadow: 5px 5px 0px #035081;
}

.default-rounded{
    border-radius: 25px !important;
}

.brand-green-color{
    background-color: $brand-green !important;
}

@mixin green-button{
    background-color: #009688;
    color: #FFF !important;
    border-radius: 5px;
    font-weight: 700;
}

@mixin orange-button{
    background-color: $primary-color;
    color: #FFF !important;
    border-radius: 5px;
    font-weight: 700;
}


