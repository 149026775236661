
// Variables
@import 'variables';
@import "common";
//@import '~bootstrap/scss/bootstrap';


#navbarDropdown{
    color: #FFF;
}

.main-title{
    border-left: 5px solid #333;
    padding-left: 10px;
    margin: 20px 0;
}

.nav-link{
    border-radius: 5px;
    padding: 0.35rem 1rem;
}

.nav-link:hover{
    color: #fff !important;
    background-color: #0774b9;
    border-radius: 5px;
    //box-shadow: 2px 2px 10px #bbb;
}

.nav-link.active{
    background-color: #ffc107;
    color: #333 !important;
}

.custom-tab-multi-language{
    .nav-tabs{
        border-radius: 0;
    }
    .nav-link{
        border-radius: 0;
    }
}

.sidebar-wrapper .sidebar-header img{
    height: 2.3rem;
}

.page-heading{
    margin-bottom: 0;
}

.card-header{
    padding-bottom: 0;
}

.bootstrap-tagsinput .badge{
    margin: 2px 3px !important;
    background-color: #03a9f4;
}
